<template>
    <nav>
        <ul>
            <li v-for="step in 5" :key="step">
                <router-link :to="`/step-${step}`">{{ step }}</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "StepNav"
    }
</script>

<style lang="scss" scoped>
    nav {
        position: fixed;
        top: 25vh;
        width: 2rem;
        height: 50vh;

        @include desktop {
            position: absolute;
            top: 6.25rem;
            width: 100%;
            height: auto;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include desktop {
                height: auto;
                flex-direction: row;
            }

            li {
                flex-grow: 1;

                @include desktop {
                    flex-grow: 0;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: $ci-color-grey;
                    padding: .1rem;
                    margin: 0 .5rem;
                    border-bottom: 1px solid transparent;
                    transition: all .5s;

                    &:hover {
                        color: $ci-color-dark;
                    }

                    &.router-link-active {
                        color: $ci-color-dark;
                        pointer-events: none;
                        transform: scale(1.2);

                        @include desktop {
                            border-bottom: 1px solid $ci-color-dark;
                            transform: none;
                        }
                    }
                }
            }
        }
    }
</style>