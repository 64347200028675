<template>
    <div class="choices">
      <inline-svg v-for="(svg, index) in chosen" :key="index" @loaded="initTimeline($event, svg.step, svg.choice)" class="icon" :src="svg.src" :alt="svg.alt" />
    </div>
</template>

<script>
import gsap from 'gsap'
import InlineSvg from 'vue-inline-svg';
import timelinesMixin from "../mixins/timelinesMixin";

let gfx = {
  svgLand: require('../assets/gfx/anim_land.svg'),
  svgStadt: require('../assets/gfx/anim_stadt.svg'),

  svgViel: require('../assets/gfx/anim_viel.svg'),
  svgWenig: require('../assets/gfx/anim_wenig.svg'),

  svgPower: require('../assets/gfx/anim_power.svg'),
  svgUmwelt: require('../assets/gfx/anim_umwelt.svg'),

  svgKonventionell: require('../assets/gfx/anim_konventionell.svg'),
  svgHybrid: require('../assets/gfx/anim_hybrid.svg'),
  svgElektrisch: require('../assets/gfx/anim_elektrisch.svg'),

  svgKlein: require('../assets/gfx/anim_klein.svg'),
  svgGross: require('../assets/gfx/anim_gross.svg'),
}

export default {
  name: "Choices",
  mixins: [timelinesMixin],
  components: {
    InlineSvg
  },
  props: [ 'choices' ],
  data: function () {
    return {
      svgLoadedCounter: 0
    }
  },
  computed: {
    chosen() {
      let result = [];

      if (this.choices.step1 === 1)
        result.push({ src: gfx.svgKonventionell, alt: 'Konventionell', step: 'step1', choice: 1 });
      else if (this.choices.step1 === 2)
        result.push({ src: gfx.svgHybrid, alt: 'Hybrid', step: 'step1', choice: 2 });
      else if (this.choices.step1 === 3)
        result.push({ src: gfx.svgElektrisch, alt: 'Elektrisch', step: 'step1', choice: 3 });

      if (this.choices.step2 === 1)
        result.push({ src: gfx.svgLand, alt: 'Gelände', step: 'step2', choice: 1 });
      else if (this.choices.step2 === 2)
        result.push({ src: gfx.svgStadt, alt: 'Stadt', step: 'step2', choice: 2 });

      if (this.choices.step3 === 1)
        result.push({ src: gfx.svgViel, alt: 'Viel', step: 'step3', choice: 1 });
      else if (this.choices.step3 === 2)
        result.push({ src: gfx.svgWenig, alt: 'Wenig', step: 'step3', choice: 2 });

      if (this.choices.step4 === 1)
        result.push({ src: gfx.svgPower, alt: 'Power', step: 'step4', choice: 1 });
      else if (this.choices.step4 === 2)
        result.push({ src: gfx.svgUmwelt, alt: 'Umwelt', step: 'step4', choice: 2 });
      
      if (this.choices.step5 === 1)
        result.push({ src: gfx.svgKlein, alt: 'Klein', step: 'step5', choice: 1 });
      else if (this.choices.step5 === 2)
        result.push({ src: gfx.svgGross, alt: 'Groß', step: 'step5', choice: 2 });

      return result;
    }
  },
  methods: {
    initTimeline: function (svg, step, choice) {
      let tl = this.getTimeline(svg, step, choice);

      gsap.to(tl, {duration: 1, progress: 1, delay: this.svgLoadedCounter * .25});

      this.svgLoadedCounter++;
    }
  }
}
</script>

<style scoped lang="scss">
  div.choices {
    display: block !important;

    > svg {
      //width: auto !important;
      width: 20% !important;
      min-width: 10rem;
      max-width: 20rem;
      cursor: default !important;

      @include mobile {
        width: 50% !important;
        min-width: auto;
      }
    }
  }
</style>