<template>
    <router-link v-if="to" v-bind:to="to" class="button">
        <span><slot></slot></span>
    </router-link>
    <a v-else-if="href" v-bind:href="href" class="button" target="_blank" rel="nofollow">
        <span><slot></slot></span>
    </a>
    <button v-else class="button">
        <span><slot></slot></span>
    </button>
</template>

<script>
    export default {
        name: "DefaultButton",
        props: ['to', 'href']
    }
</script>

<style lang="scss">
    a.button, button.button {
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        color: white;
        text-decoration: none;
        font-weight: bold;
        padding: 1.5rem;
        text-align: center;
        min-width: 12rem;
        border: none;
        font-size: 1rem;
        font-family: Urbane, Helvetica, Arial, sans-serif;
        //overflow: hidden;
        background-color: $ci-color-dark;
        border-radius: .5rem;
        text-transform: uppercase;

        > span {
            position: relative;
            mix-blend-mode: difference;
            z-index: 2;
        }

        &::before {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            max-width: 0;
            z-index: 1;
            background-color: white;
            /*transform: scaleX(0);*/
            /*transform-origin: left center;*/
            /*transition: transform .25s;*/
            transition: max-width .25s;
            border-radius: .5rem;
        }

        &:link, &:active, &:visited {
            color: white;
        }

        &:hover {
            &::before {
                //transform: scaleX(1);
                max-width: 102%;
            }
        }

        &.inverted {
            background-color: invert($ci-color-dark);

            &::before {
                background-color: black;
            }
        }

        &.emphasized {
            background-color: $ci-color-emphasized;
            transition: color .25s;

            > span {
                mix-blend-mode: normal;
            }

            &:hover {
                color: $ci-color-emphasized;
            }
        }
    }

</style>