<template>
    <section>
        <h1>Wo bist du am häufigsten mit dem Auto unterwegs?</h1>
        <div class="choices">
            <inline-svg @click.native="clickHandler(1, $event, timeline1)" ref="choice1" @loaded="setupTimeline($event, initTimeline1($event))" class="icon" :src="gfx.svgLand" alt="Land" />
            <inline-svg @click.native="clickHandler(2, $event, timeline2)" ref="choice2" @loaded="setupTimeline($event, initTimeline2($event))" class="icon" :src="gfx.svgStadt" alt="Stadt" />
        </div>
        <DefaultButton @click.native="clickHandler(0, $event, null)">Keine Angabe</DefaultButton>
    </section>
</template>


<script>
    import gsap from 'gsap'
    import InlineSvg from 'vue-inline-svg';
    import DefaultButton from "../components/DefaultButton";
    import stepMixin from "../mixins/stepMixin";

    let gfx = {
        svgLand: require('../assets/gfx/anim_land.svg'),
        svgStadt: require('../assets/gfx/anim_stadt.svg'),
    }

    export default {
        name: "step2",
        mixins: [stepMixin],
        components: {
            DefaultButton,
            InlineSvg
        },
        props: [ 'choices' ],
        data: function() {
          return {
              gfx,
              timeline1: null,
              timeline2: null
          }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }

        },
        methods: {
            initTimeline1: function (svg) {
                gsap.defaults({ease: "power2.inOut"});
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    sun: svg.querySelector('#sun'),
                    car: svg.querySelector('#car'),
                    trees: svg.querySelectorAll('#trees > *'),
                    mountainGrey: svg.querySelector('#mountain-grey'),
                    mountainWhite: svg.querySelector('#mountain-white'),
                    cloud1: svg.querySelector('#cloud-1'),
                    cloud2: svg.querySelector('#cloud-2'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.car, {duration: .5, x: '-=200', opacity: 0}, 0)
                    .from(els.trees, {duration: .2, scale: 0, transformOrigin: 'center bottom', stagger: .1}, 0)
                    .from(els.mountainWhite, {duration: .5, fill: '#f5f1e5'}, 0)
                    .from(els.mountainGrey, {duration: .5, fill: '#000000'}, 0)
                    .from(els.sun, {duration: .5, scale: 0, rotation: -180, y:'-=100', transformOrigin: 'center center'}, 0)
                    .from(els.cloud1, {duration: .5, opacity: 0, x: '-=50'}, 0)
                    .from(els.cloud2, {duration: .5, opacity: 0, x: '+=50'}, 0)

                this.timeline1 = tl;
                return this.timeline1;
            },
            initTimeline2: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    moon: svg.querySelector('#moon'),
                    person: svg.querySelector('#person'),
                    car1: svg.querySelector('#car-1'),
                    car2: svg.querySelector('#car-2'),
                    car3: svg.querySelector('#car-3'),
                    building1: svg.querySelector('#building-1'),
                    building2: svg.querySelector('#building-2'),
                    litWindows: svg.querySelector('#lit-windows'),
                    trafficLight: svg.querySelectorAll('#traffic-light'),
                    cloud1: svg.querySelector('#cloud-1'),
                    cloud2: svg.querySelector('#cloud-2'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from([els.car2, els.car1], {duration: .4, x: '-=200', opacity: 0, stagger: .1}, 0)
                    .from(els.car3, {duration: .5, x: '+=200', opacity: 0}, 0)
                    .from([els.person, els.trafficLight], {duration: .5, scale: 0, transformOrigin: 'center bottom'}, 0)
                    .from(els.litWindows, {duration: .5, opacity: 0}, 0)
                    .from([els.building1, els.building2], {duration: .5, fill: '#000000'}, 0)
                    .from(els.moon, {duration: .5, opacity: 0, y: '+=100', transformOrigin: 'center center'}, 0)
                    .from(els.cloud1, {duration: .5, opacity: 0, x: '-=50'}, 0)
                    .from(els.cloud2, {duration: .5, opacity: 0, x: '+=50'}, 0)

                this.timeline2 = tl;
                return this.timeline2;
            }
        }
    }
</script>

<style scoped>

</style>