<template>
    <section>
        <h1>Deine Auswahl:</h1>
        <Choices :choices="choices"></Choices>
        <p>
          Mit Klick auf den Button erhältst Du eine Übersicht über alle Automodelle, die Deinen Anforderungen entsprechen.
        </p>
        <div class="button-group">
            <DefaultButton class="emphasized" :href="goDriveLink">Ergebnis anzeigen</DefaultButton>
            <DefaultButton to="/step-1">Wiederholen</DefaultButton>
        </div>
    </section>
</template>

<script>
    import DefaultButton from "../components/DefaultButton";
    import Choices from "@/components/Choices";

    export default {
        name: "result",
        components: {DefaultButton, Choices},
        props: ['filter', 'choices'],
        computed: {
            goDriveLink: function () {
                return 'https://godrive.at/Suche/' + this.filter;
            }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }
        },
    }
</script>

<style scoped>

</style>