<template>
    <section>
        <h1>Die gesuchte Seite konnte nicht gefunden werden!</h1>
    </section>
</template>

<script>
    export default {
        name: "notFound",
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }
        },
    }
</script>

<style scoped>

</style>