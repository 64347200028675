<template>
  <section>
    <h1>Impressum</h1>
    <p><strong>Für den Inhalt verantwortlich</strong></p>
    <p>W1 OMNICHANNEL MARKETING GMBH<br>
      Wollzeile 1<br>
      <a href="mailto:office@w1.at">office@w1.at</a><br>
      1010 Wien / Austria<br>
      REG: FN291728m<br>
      UID: ATU63273512
    </p>
  </section>
</template>

<script>
export default {
  name: "imprint",
  mounted() {
    if(typeof window.gtag === 'function') {
      window.gtag('event', 'page_view')
    }
  },
}
</script>

<style scoped>

</style>