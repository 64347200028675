<template>
  <section>
    <h1>Datenschutzerklärung</h1>
    <p>
      Diese Webseite wird von W1 OMNICHANNEL MARKETING betrieben. Sie dient dazu, BesucherInnen über unser Unternehmen, unsere Leistungen und Services zu informieren. Ein Direktvertrieb von Produkten oder Services über die Webseite erfolgt nicht.
    </p>
    <h2>Gegenstand dieser Datenschutzerklärung</h2>
    <p>Das Vertrauen unserer KundInnen, GeschäftspartnerInnen und BesucherInnen unserer Homepage hat bei uns oberste Priorität. Daher ist Datenschutz für uns von allerhöchster Bedeutung. Mit dieser Datenschutzerklärung informieren wir Sie darüber, welche Daten von UserInnen unserer Webseite erhoben werden und was mit diesen passiert bzw. wie und ob diese verarbeitet werden.
      Für den Fall, dass Sie mit den Bedingungen dieser Datenschutzerklärung nicht einverstanden sind, bitten wir Sie, Ihren Internetbrowser so einzustellen, dass dieser keine Cookies akzeptiert.
      Die gegenständliche Datenschutzerklärung ist ausschließlich auf die mit dem Betrieb der Webseite verbundene Datenerhebung bzw. -verarbeitung beschränkt.
    </p>
    <h2>Erhebung von Daten</h2>
    <p>Wenn Sie unsere Webseite besuchen, werden die von Ihrem Computer oder mobilem Endgerät übermittelten Daten automatisiert erfasst. Es werden jedoch keine personenbezogenen Daten erhoben und verarbeitet, ebenso wird keine Verknüpfung von Daten, die bei Ihrem Besuch erhoben werden, mit sonstigen verfügbaren Daten vorgenommen.
      Folgende Daten werden automatisiert erhoben: Geräte-ID, Gerätetyp, Computer- und Verbindungsinformationen, statistische Daten zu Seitenaufrufen, Datenverkehrszeiten zu und von den Websites, sowie Referral-URL.
    </p>
    <h2>Verarbeitung und Weitergabe von Daten</h2>
    <p>
      Die von Ihnen erhobenen Daten, aufgrund des Besuchs unserer Webseite, werden von W1 nicht gespeichert oder verarbeitet.
    </p>
    <h2>Änderung dieser Datenschutzerklärung</h2>
    <p>
      Sollten Änderungen an dieser Datenschutzerklärung vorgenommen werden, werden wir diese auf unserer Webseite veröffentlichen.
      Verlinkung auf Webseiten und Inhalte Dritter
      Einige Inhalte dieser Webseite können auf eine andere Website und deren Inhalte referenzieren. Diese Webseite kann differierende Datenschutzbestimmungen aufweisen.
    </p>
    <h2>Cookie Nutzung</h2>
    <p>
      Cookie Nutzung
      <a href="/">findmycar.at</a> verwendet Cookies, das sind kleine Textdateien, die von einem Internet-Server auf
      Ihrer Festplatte lokal gespeichert werden. Durch sie wird die Speicherung bestimmter Einstellungen und Daten
      über Ihren Browser ermöglicht. Diese Cookies werden von uns eingesetzt, um unsere Webseite und die damit
      verbundenen Services für Sie bestmöglich zu optimieren. Durch die von uns verwendeten Cookies werden keine
      personenbezogenen Daten gespeichert, sondern lediglich anonymisierte Daten.

      <a href="/">findmycar.at</a> nutzt Cookies von Google Analytics, um Informationen über die Nutzung der Website zu
      erfahren.
    </p>
    <h2>Web Fonts von Adobe Typekit</h2>
    <p>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Adobe Typekit bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Adobe Typekit aufnehmen. Hierdurch erlangt Adobe Typekit Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Adobe Typekit Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
      Weitere Informationen finden Sie in der Datenschutzerklärung von Adobe Typekit: <a href="https://www.adobe.com/at/privacy/policies/adobe-fonts.html" target="_blank" rel="noopener">https://www.adobe.com/at/privacy/policies/adobe-fonts.html</a>
    </p>
    <h2>Verwendung von Daten dieser Webseite</h2>
    <p>
      Wir möchten Sie auf die Urheberschaft der Daten dieser Webseite hinweisen, erlauben aber interessierten Dritten unsere Daten zu verwenden. Eine weitergehende Nutzung, speziell die unseres Logos, bedarf einer ausdrücklichen Zustimmung der W1 OMNICHANNEL MARKETING GmbH.
    </p>
    <p>
      Sollten Sie Fragen betreffender dieser Datenschutzerklärung haben, wenden Sie sich bitte direkt an uns.
    </p>
    <p>
      Tel.: <a href="tel:+4312979797">+43 (0) 1 297 97 97</a><br>
      <a href="mailto:office@w1.at">office@w1.at</a>
    </p>
  </section>
</template>

<script>
export default {
  name: "privacy_policy",
  mounted() {
    if(typeof window.gtag === 'function') {
      window.gtag('event', 'page_view')
    }
  },
}
</script>

<style scoped>

</style>