<template>
  <div id="app" v-bind:class="{ reachedTheBottom: atTheBottom }">
    <header v-bind:class="headerSize">
      <router-link to="/" id="brand">
        <picture>
          <source :media="'(max-width: ' + $globalConstants.breakpoints.mobileMax + 'px)'" v-bind:srcset="gfx.logoMobile">
          <source :media="'(min-width: ' + $globalConstants.breakpoints.desktopMin + 'px)'" v-bind:srcset="gfx.logoDesktop">
          <img v-bind:src="gfx.logoDesktop" alt="Find My Car">
        </picture>
      </router-link>
    </header>
    <transition name="nav-transition"
                v-on:before-enter="beforeEnterNav"
                v-on:enter="enterNav"

                v-on:before-leave="beforeLeaveNav"
                v-on:leave="leaveNav">
      <router-view name="navigation"></router-view>
    </transition>
    <main>
      <transition name="step-transition" mode="out-in"
                  v-on:before-enter="beforeEnter"
                  v-on:enter="enter"

                  v-on:before-leave="beforeLeave"
                  v-on:leave="leave">
        <router-view :choices="choices" @choose="choose" :filter="filter"></router-view>
      </transition>
    </main>
    <footer>
      <a href="https://godrive.at" rel="nofollow" target="_blank" title="GO!drive - der unabhängige Autoberater">
        <img v-bind:src="gfx.logoGodrive">
      </a>
      <nav>
        <ul>
          <li>
            <router-link to="/impressum">Impressum</router-link>
          </li>
          <li>
            <router-link to="/datenschutz">Datenschutz</router-link>
          </li>
        </ul>
      </nav>
    </footer>
    <ScrollIndicator></ScrollIndicator>
  </div>
</template>

<script>
import gsap from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import ScrollIndicator from "./components/ScrollIndicator";

gsap.registerPlugin(ScrollToPlugin);

let gfx = {
  logoDesktop: require('./assets/gfx/logo-desktop.svg'),
  logoMobile: require('./assets/gfx/logo-mobile.svg'),
  logoGodrive: require('./assets/gfx/logo-godrive.svg'),
}

export default {
  name: 'App',
  data: function() {
    return {
      pageTransitionDuration: .5,
      transitionDirection: null,
      gfx,
      atTheBottom: true,
      choices: {
        step1: null,
        step2: null,
        step3: null,
        step4: null,
        step5: null,
      },
      filter: "",
      isMobile: null,
    }
  },
  components: {
    ScrollIndicator
    //HelloWorld
  },
  computed: {
    headerSize: function () {
      switch (this.$route.name) {
        case 'start':
          return 'large';
        case 'result':
          return 'medium';
        default:
          return '';
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.transitionDirection = to.meta.order > from.meta.order ? 1 : -1;
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  methods: {
    beforeEnter: function (el) {
      gsap.set(el, {opacity: 0, x: this.isMobile ? 0 : this.transitionDirection * 100, y: this.isMobile ? this.transitionDirection * 100 : 0})
    },
    enter: function (el, done) {
      gsap.to(el, {duration: this.pageTransitionDuration/2, opacity: 1, x: 0, y: 0, onComplete: done})
    },
    beforeLeave: function (el) {
      gsap.set(el, {opacity: 1, x: 0, y: 0})
    },
    leave: function (el, done) {
      gsap.to(el, {duration: this.pageTransitionDuration/2, opacity: 0, x: this.isMobile ? 0 : this.transitionDirection * (-100), y: this.isMobile ? this.transitionDirection * (-100) : 0, onComplete: done})
    },
    beforeEnterNav: function (el) {
      gsap.set(el.querySelectorAll('li'), {opacity: 0})
    },
    enterNav: function (el, done) {
      gsap.to(el.querySelectorAll('li'), {duration: this.pageTransitionDuration, opacity: 1, stagger: .1, onComplete: done})
    },
    beforeLeaveNav: function (el) {
      gsap.set(el.querySelectorAll('li'), {opacity: 1})
    },
    leaveNav: function (el, done) {
      gsap.to(el.querySelectorAll('li'), {duration: this.pageTransitionDuration, opacity: 0, stagger: .1, onComplete: done})
    },
    choose: function (choice) {
      this.choices[this.$route.name] = choice;
      this.updateFilter();
      this.$router.push({name: this.$route.meta.next});
    },
    resizeHandler: function () {
      this.isMobile = window.matchMedia('(max-width: ' + this.$globalConstants.breakpoints.mobileMax + 'px)').matches;
    },
    updateFilter: function () {
      let filter = [
        ...(this.choices.step1 === 3 ? ["Elektro"] : []),
        ...(this.choices.step1 === 2 ? ["Hybrid"] : []),
        ...(this.choices.step1 === 1 ? ["Diesel"] : []),
        ...(this.choices.step1 === 1 ? ["Benzin"] : []),
        ...(this.choices.step2 === 1 ? ["Gelände"] : []),
        ...(this.choices.step2 === 2 ? ["Stadt"] : []),
        ...(this.choices.step4 === 1 ? ["Leistung"] : []),
        ...(this.choices.step5 === 2 ? ["Luxus"] : []),
        ...(this.choices.step3 === 1 ? ["Kofferraum"] : []),
        ...(this.choices.step5 === 1 ? ["Preiswert"] : []),
        ...(this.choices.step4 === 2 ? ["Umwelt"] : []),
      ];

      this.filter = filter.join("_");
    }
    
  }
}
</script>

<style lang="scss">
  #app {
    font-family: Urbane, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $ci-color-dark;
    overflow: hidden;
  }

  body {
    background-color: $ci-color-light;
    margin: 0;
  }

  h1 {
    font-size: 1.2em;

    @include desktop {
      font-size: 1.5em;
    }
  }

  a {
    color: black;
  }

  header {
    position: relative;
    text-align: left;
    min-height: 5.5rem;
    transition: min-height .5s;

    @include desktop {
      min-height: 5rem;
    }

    a#brand {
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      max-width: 3.4rem;
      margin-left: 1.5rem;
      transition: all .5s;

      @include desktop {
        max-width: 12rem;
        margin-left: 1.5rem;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &.large {
      $logo-width: 60vw;
      $logo-height: $logo-width/$logo-mobile-ratio;

      min-height: calc(#{$logo-height} + 2rem);

      @include desktop {
        min-height: 50vh;
      }

      a#brand {
        max-width: $logo-width;
        margin-left: (100vw - $logo-width)/2;

        @include desktop {
          max-width: 30rem;
          margin-left: calc(100vw / 2 - 15rem);
        }
      }
    }

    &.medium {
      $logo-width: 40vw;
      $logo-height: $logo-width/$logo-mobile-ratio;

      min-height: calc(#{$logo-height} + 2rem);

      @include desktop {
        min-height: 10rem;
      }

      a#brand {
        max-width: $logo-width;
        margin-left: (100vw - $logo-width)/2;

        @include desktop {
          max-width: 15rem;
          margin-left: calc(100vw / 2 - 7.5rem);
        }
      }
    }
  }

  nav {
    z-index: 2;
  }

  main {
    transition: height .5s;
    margin: 0 2rem 3rem 2rem;
    //min-height: calc(100vh - 5.4rem - 9rem);
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;

    @include desktop {
      margin: 3rem 0;
    }

    section {
      padding: 0 1rem;

      @include desktop {
        padding: 0 2rem;
      }
    }
  }

  p {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }

  footer {
    margin-top: 0;

    @include desktop {
      border-top: 1px solid $ci-color-dark;
    }

    > a {
      display: inline-block;
      margin: 1.2rem;
    }

    nav {
      ul {
        list-style-type: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0 1rem 1rem 1rem;

        li {
          margin: .5rem;

          a {
            color: black;
          }
        }
      }
    }
  }

</style>
