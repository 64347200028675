<template>
    <section>
        <h1>Worauf achtest du beim Fahrzeugkauf besonders?</h1>
        <div class="choices">
            <inline-svg @click.native="clickHandler(1, $event, timeline1)" ref="choice1" @loaded="setupTimeline($event, initTimeline1($event))" class="icon" :src="gfx.svgPower" alt="Power" />
            <inline-svg @click.native="clickHandler(2, $event, timeline2)" ref="choice2" @loaded="setupTimeline($event, initTimeline2($event))" class="icon" :src="gfx.svgUmwelt" alt="Umwelt" />
        </div>
        <DefaultButton @click.native="clickHandler(0, $event, null)">Keine Angabe</DefaultButton>
    </section>
</template>

<script>
    import gsap from 'gsap'
    import InlineSvg from 'vue-inline-svg';
    import DefaultButton from "../components/DefaultButton";
    import stepMixin from "../mixins/stepMixin";

    let gfx = {
        svgPower: require('../assets/gfx/anim_power.svg'),
        svgUmwelt: require('../assets/gfx/anim_umwelt.svg'),
    }

    export default {
        name: "step4",
        mixins: [stepMixin],
        components: {
            DefaultButton,
            InlineSvg
        },
        data: function() {
            return {
                gfx,
                timeline1: null,
                timeline2: null
            }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }

        },
        methods: {
            initTimeline1: function (svg) {
                gsap.defaults({ease: "power2.inOut"});
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    pointer: svg.querySelector('#pointer'),
                    scale: svg.querySelector('#scale'),
                    scaleBG: svg.querySelectorAll('#scale-bg'),
                    scaleUpper: svg.querySelectorAll('#scale-upper'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.scaleBG, {duration: .5, fill: '#f5f1e5', scale: .85, transformOrigin: 'center bottom'}, 0)
                    .from(els.scale, {duration: .5, fill: '#000000'}, 0)
                    .from(els.scaleUpper, {duration: .5, opacity: 0}, 0)
                    .from(els.pointer, {duration: .5, fill: '#000000', rotation: '-=62', transformOrigin: '15 15'}, 0)

                this.timeline1 = tl;

                return this.timeline1;
            },
            initTimeline2: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
                    textPaths: svg.querySelectorAll('#text path'),
                    flowers: svg.querySelectorAll('#flower-1, #flower-2'),
                    tree: svg.querySelectorAll('#tree *'),
                    gras: svg.querySelector('#gras'),
                    sun: svg.querySelector('#sun')
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.tree, {duration: .3, fill: '#000000', stagger: {amount: .2}}, 0)
                    .from(els.flowers, {duration: .4, scale: 0, transformOrigin: 'center bottom', stagger: -.1}, 0)
                    .from(els.gras, {duration: .5, scaleY: 0, transformOrigin: 'center bottom'}, 0)
                    .from(els.sun, {duration: .5, y: '-=100', scale: 0, rotation: -180, transformOrigin: 'center center'}, 0)

                this.timeline2 = tl;

                return this.timeline2;
            }
        }
    }
</script>

<style scoped>

</style>