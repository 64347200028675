<template>
    <StepNav></StepNav>
</template>

<script>
    import StepNav from "../components/StepNav";
    export default {
        name: "Navigation",
        components: {StepNav}
    }
</script>

<style scoped>

</style>