export default {
    methods: {
        setupTimeline: function (svg, timeline) {
            svg.addEventListener('mouseenter', function () {
                timeline.play();
            });
            svg.addEventListener('mouseleave', function () {
                timeline.reverse();
            });
        },
        clickHandler: function (choice, event, timeline) {
            if (timeline !== null) {
                if (timeline.progress() < 1) {
                    let that = this;

                    timeline.eventCallback('onComplete', function () {
                        that.$emit('choose', choice);
                    }).play();
                }
                else {
                    this.$emit('choose', choice);
                }
            }
            else {
                this.$emit('choose', choice);
            }
        }
    }
}