<template>
    <section>
        <h1>Wie groß ist dein Budget?</h1>
        <div class="choices">
            <inline-svg @click.native="clickHandler(1, $event, timeline1)" ref="choice1" @loaded="setupTimeline($event, initTimeline1($event))" class="icon" :src="gfx.svgKlein" alt="Klein" />
            <inline-svg @click.native="clickHandler(2, $event, timeline2)" ref="choice2" @loaded="setupTimeline($event, initTimeline2($event))" class="icon" :src="gfx.svgGross" alt="Groß" />
        </div>
        <DefaultButton @click.native="clickHandler(0, $event, null)">Keine Angabe</DefaultButton>
    </section>
</template>

<script>
    import gsap from 'gsap'
    import InlineSvg from 'vue-inline-svg';
    import DefaultButton from "../components/DefaultButton";
    import stepMixin from "../mixins/stepMixin";

    let gfx = {
        svgKlein: require('../assets/gfx/anim_klein.svg'),
        svgGross: require('../assets/gfx/anim_gross.svg'),
    }

    export default {
        name: "step5",
        mixins: [stepMixin],
        components: {
            DefaultButton,
            InlineSvg
        },
        data: function() {
            return {
                gfx,
                timeline1: null,
                timeline2: null,
            }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }
        },
        methods: {
            initTimeline1: function (svg) {
                gsap.defaults({ease: "power2.inOut"});
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    piggy: svg.querySelectorAll('#piggy'),
                    coin: svg.querySelector('#coin'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 120%'}, 0)
                    .from(els.coin, {duration: .5, y: '-=100', opacity: 0, rotation: -360, transformOrigin: 'center center'}, 0)
                    .from(els.piggy, {duration: .5, fill: '#000000', scale: .9, transformOrigin: 'center bottom'}, 0)

                return this.timeline1 = tl;
            },
            initTimeline2: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    bills: svg.querySelectorAll('#money > g'),
                    fromBlack: svg.querySelectorAll('#to-black > *'),
                    fromWhite: svg.querySelectorAll('#euro, #stripe-1'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.fromBlack, {duration: .25, fill: '#000000'}, 0)
                    .from(els.fromWhite, {duration: .25, fill: '#f5f1e5'}, 0)
                    .to(els.bills, {
                        duration: .5,
                        rotation: function(index) { return index * -3; },
                        x: function(index) { return index * 5; },
                        y: function(index) { return index * -5; },
                        transformOrigin: '0 -20'
                    }, 0)

                return this.timeline2 = tl;
            }
        }
    }
</script>

<style scoped>

</style>