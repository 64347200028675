<template>
    <section>
        <h1>Mit präzisen Fragen & passgenauen Antworten zum perfekten Automodell!</h1>
        <h2>Finde heraus, welches Auto zu dir passt!</h2>
        <DefaultButton to="/step-1">Los geht´s</DefaultButton>
    </section>
</template>

<script>
    import DefaultButton from "../components/DefaultButton";

    export default {
        name: "start",
        components: {DefaultButton},
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }
        },
    }
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 2em;
  }
  section {
      align-self: center;
  }
</style>