<template>
    <section>
        <h1>Wieviel Gepäck transportierst du im Auto mit?</h1>
        <div class="choices">
            <inline-svg @click.native="clickHandler(1, $event, timeline1)" ref="choice1" @loaded="setupTimeline($event, initTimeline1($event))" class="icon" :src="gfx.svgViel" alt="Viel" />
            <inline-svg @click.native="clickHandler(2, $event, timeline2)" ref="choice2" @loaded="setupTimeline($event, initTimeline2($event))" class="icon" :src="gfx.svgWenig" alt="Wenig" />
        </div>
        <DefaultButton @click.native="clickHandler(0, $event, null)">Keine Angabe</DefaultButton>
    </section>
</template>

<script>
    import gsap from 'gsap'
    import InlineSvg from 'vue-inline-svg';
    import DefaultButton from "../components/DefaultButton";
    import stepMixin from "../mixins/stepMixin";

    let gfx = {
        svgViel: require('../assets/gfx/anim_viel.svg'),
        svgWenig: require('../assets/gfx/anim_wenig.svg'),
    }

    export default {
        name: "step3",
        mixins: [stepMixin],
        components: {
            DefaultButton,
            InlineSvg
        },
        data: function() {
            return {
                gfx,
                timeline1: null,
                timeline2: null,
            }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }

        },
        methods: {
            initTimeline1: function (svg) {
                gsap.defaults({ease: "power2.inOut"});
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    luggage: svg.querySelectorAll('#luggage-1, #luggage-2'),
                    ball: svg.querySelector('#ball'),
                    pram: svg.querySelector('#pram'),
                    longboard: svg.querySelector('#longboard'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 180%'}, 0)
                    .from(els.ball, {duration: .5, y: '-=100', transformOrigin: 'center center', ease: 'bounce.out'}, 0)
                    .from(els.ball, {duration: .2, opacity: 0}, 0)
                    .from(els.luggage, {duration: .5, fill: '#000000'}, 0)
                    .from(els.pram, {duration: .5, x:'+=100', opacity: 0}, 0)
                    .from(els.longboard, {duration: .5, scaleY: 0, transformOrigin: 'center bottom'}, 0)

                this.timeline1 = tl;

                return this.timeline1;
            },
            initTimeline2: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    phone: svg.querySelector('#phone'),
                    laptop: svg.querySelector('#laptop'),
                    case: svg.querySelectorAll('#case > *:not(#latches)'),
                    latches: svg.querySelector('#latches'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 150%'}, 0)
                    .from(els.case, {duration: .5, fill: '#000000'}, 0)
                    .from(els.latches, {duration: .5, fill: '#f5f1e5'}, 0)
                    .from(els.laptop, {duration: .5, x: '+=100', opacity: 0}, 0)
                    .from(els.phone, {duration: .5, x: '-=100', opacity: 0}, 0)

                this.timeline2 = tl;

                return this.timeline2;
            }
        }
    }
</script>

<style scoped>

</style>