import gsap from "gsap";

export default {
    methods: {
        getTimeline(svg, step, choice) {
            let timelines = {
                step1: [
                    function (svg) {
                    gsap.defaults({ease: "power2.inOut"});
                    let tl = gsap.timeline({paused: true});

                    let els = {
                        text: svg.querySelector('#text'),
                        textPaths: svg.querySelectorAll('#text path'),
                        sun: svg.querySelector('#sun'),
                        car: svg.querySelector('#car'),
                        trees: svg.querySelectorAll('#trees > *'),
                        mountainGrey: svg.querySelector('#mountain-grey'),
                        mountainWhite: svg.querySelector('#mountain-white'),
                        cloud1: svg.querySelector('#cloud-1'),
                        cloud2: svg.querySelector('#cloud-2'),
                    };

                    tl
                        .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                        .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                        .from(els.car, {duration: .5, x: '-=200', opacity: 0}, 0)
                        .from(els.trees, {duration: .2, scale: 0, transformOrigin: 'center bottom', stagger: .1}, 0)
                        .from(els.mountainWhite, {duration: .5, fill: '#f5f1e5'}, 0)
                        .from(els.mountainGrey, {duration: .5, fill: '#000000'}, 0)
                        .from(els.sun, {
                            duration: .5,
                            scale: 0,
                            rotation: -180,
                            y: '-=100',
                            transformOrigin: 'center center'
                        }, 0)
                        .from(els.cloud1, {duration: .5, opacity: 0, x: '-=50'}, 0)
                        .from(els.cloud2, {duration: .5, opacity: 0, x: '+=50'}, 0)

                    return tl;
                },
                    function (svg) {
                    let tl = gsap.timeline({paused: true});

                    let els = {
                        text: svg.querySelector('#text'),
                        textPaths: svg.querySelectorAll('#text path'),
                        moon: svg.querySelector('#moon'),
                        person: svg.querySelector('#person'),
                        car1: svg.querySelector('#car-1'),
                        car2: svg.querySelector('#car-2'),
                        car3: svg.querySelector('#car-3'),
                        building1: svg.querySelector('#building-1'),
                        building2: svg.querySelector('#building-2'),
                        litWindows: svg.querySelector('#lit-windows'),
                        trafficLight: svg.querySelectorAll('#traffic-light'),
                        cloud1: svg.querySelector('#cloud-1'),
                        cloud2: svg.querySelector('#cloud-2'),
                    };

                    tl
                        .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                        .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                        .from([els.car2, els.car1], {duration: .4, x: '-=200', opacity: 0, stagger: .1}, 0)
                        .from(els.car3, {duration: .5, x: '+=200', opacity: 0}, 0)
                        .from([els.person, els.trafficLight], {
                            duration: .5,
                            scale: 0,
                            transformOrigin: 'center bottom'
                        }, 0)
                        .from(els.litWindows, {duration: .5, opacity: 0}, 0)
                        .from([els.building1, els.building2], {duration: .5, fill: '#000000'}, 0)
                        .from(els.moon, {duration: .5, opacity: 0, y: '+=100', transformOrigin: 'center center'}, 0)
                        .from(els.cloud1, {duration: .5, opacity: 0, x: '-=50'}, 0)
                        .from(els.cloud2, {duration: .5, opacity: 0, x: '+=50'}, 0)

                    return tl;
                },
                    ],
                step2: [
                    function (svg) {
                        gsap.defaults({ease: "power2.inOut"});
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),
                            luggage: svg.querySelectorAll('#luggage-1, #luggage-2'),
                            ball: svg.querySelector('#ball'),
                            pram: svg.querySelector('#pram'),
                            longboard: svg.querySelector('#longboard'),
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 180%'}, 0)
                            .from(els.ball, {
                                duration: .5,
                                y: '-=100',
                                transformOrigin: 'center center',
                                ease: 'bounce.out'
                            }, 0)
                            .from(els.ball, {duration: .2, opacity: 0}, 0)
                            .from(els.luggage, {duration: .5, fill: '#000000'}, 0)
                            .from(els.pram, {duration: .5, x: '+=100', opacity: 0}, 0)
                            .from(els.longboard, {duration: .5, scaleY: 0, transformOrigin: 'center bottom'}, 0)

                        return tl;
                    },
                    function (svg) {
                    let tl = gsap.timeline({paused: true});

                    let els = {
                        text: svg.querySelector('#text'),
                        textPaths: svg.querySelectorAll('#text path'),
                        phone: svg.querySelector('#phone'),
                        laptop: svg.querySelector('#laptop'),
                        case: svg.querySelectorAll('#case > *:not(#latches)'),
                        latches: svg.querySelector('#latches'),
                    };

                    tl
                        .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                        .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 150%'}, 0)
                        .from(els.case, {duration: .5, fill: '#000000'}, 0)
                        .from(els.latches, {duration: .5, fill: '#f5f1e5'}, 0)
                        .from(els.laptop, {duration: .5, x: '+=100', opacity: 0}, 0)
                        .from(els.phone, {duration: .5, x: '-=100', opacity: 0}, 0)

                    return tl;
                },
                ],
                step3: [
                    function (svg) {
                        gsap.defaults({ease: "power2.inOut"});
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),
                            pointer: svg.querySelector('#pointer'),
                            scale: svg.querySelector('#scale'),
                            scaleBG: svg.querySelectorAll('#scale-bg'),
                            scaleUpper: svg.querySelectorAll('#scale-upper'),
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                            .from(els.scaleBG, {
                                duration: .5,
                                fill: '#f5f1e5',
                                scale: .85,
                                transformOrigin: 'center bottom'
                            }, 0)
                            .from(els.scale, {duration: .5, fill: '#000000'}, 0)
                            .from(els.scaleUpper, {duration: .5, opacity: 0}, 0)
                            .from(els.pointer, {
                                duration: .5,
                                fill: '#000000',
                                rotation: '-=62',
                                transformOrigin: '15 15'
                            }, 0)

                        return tl;
                    },
                    function (svg) {
                    let tl = gsap.timeline({paused: true});

                    let els = {
                        text: svg.querySelector('#text'),
                        textPaths: svg.querySelectorAll('#text path'),
                        flowers: svg.querySelectorAll('#flower-1, #flower-2'),
                        tree: svg.querySelectorAll('#tree *'),
                        gras: svg.querySelector('#gras'),
                        sun: svg.querySelector('#sun')
                    };

                    tl
                        .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                        .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                        .from(els.tree, {duration: .3, fill: '#000000', stagger: {amount: .2}}, 0)
                        .from(els.flowers, {duration: .4, scale: 0, transformOrigin: 'center bottom', stagger: -.1}, 0)
                        .from(els.gras, {duration: .5, scaleY: 0, transformOrigin: 'center bottom'}, 0)
                        .from(els.sun, {
                            duration: .5,
                            y: '-=100',
                            scale: 0,
                            rotation: -180,
                            transformOrigin: 'center center'
                        }, 0)

                    return tl;
                },
                ],
                step4: [
                    function (svg) {
                        gsap.defaults({ease: "power2.inOut"});
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),

                            drops: svg.querySelectorAll('#drop-1, #drop-2'),
                            nozzle: svg.querySelector('#nozzle'),
                            nozzleParts: svg.querySelectorAll('#nozzle > *'),
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 100%'}, 0)
                            .from(els.nozzle, {duration: .25, rotation: 30, transformOrigin: 'center center'}, 0)
                            .from(els.nozzleParts, {duration: .5, fill: '#000000'}, 0)
                            .from(els.drops, {duration: .24, scale: 0, transformOrigin: 'center top', stagger: -.1}, .25)

                        return tl;
                    },
                    function (svg) {
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),
                            icons: svg.querySelector('#icons'),
                            dropIcon: svg.querySelector('#drop-icon'),
                            flashIcon: svg.querySelector('#flash-icon'),
                            battery: svg.querySelector('#battery'),
                            nozzle: svg.querySelectorAll('#nozzle, #drop'),
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 100%'}, 0)
                            .to(els.dropIcon, {duration: .5, x: '+=50', y: '-=30'}, 0)
                            .to(els.flashIcon, {duration: .5, x: '-=50', y: '+=30'}, 0)
                            .to(els.icons, {duration: .5, opacity: 0}, 0)
                            .from(els.battery, {duration: .5, opacity: 0, x: '+=50'}, 0)
                            .from(els.nozzle, {duration: .5, opacity: 0, x: '-=50'}, 0)

                        return tl;
                    },
                    function (svg) {
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),
                            flash: svg.querySelector('#flash'),
                            poles: svg.querySelectorAll('#minus, #plus'),
                            case: svg.querySelector('#case'),
                            caseParts: svg.querySelectorAll('#case > *'),
                            battery: svg.querySelector('#battery')
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                            .from(els.battery, {duration: .5, scale: .8, transformOrigin: 'center bottom'}, 0)
                            .from(els.caseParts, {duration: .5, fill: '#000000'}, 0)
                            .from(els.flash, {
                                duration: .5,
                                fill: '#f5f1e5',
                                scale: .5,
                                transformOrigin: 'center center'
                            }, 0)
                            .from(els.poles, {duration: .5, fill: '#f5f1e5'}, 0)

                        return tl;
                    },
                ],
                step5: [
                    function (svg) {
                        gsap.defaults({ease: "power2.inOut"});
                        let tl = gsap.timeline({paused: true});

                        let els = {
                            text: svg.querySelector('#text'),
                            textPaths: svg.querySelectorAll('#text path'),
                            piggy: svg.querySelectorAll('#piggy'),
                            coin: svg.querySelector('#coin'),
                        };

                        tl
                            .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                            .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 120%'}, 0)
                            .from(els.coin, {
                                duration: .5,
                                y: '-=100',
                                opacity: 0,
                                rotation: -360,
                                transformOrigin: 'center center'
                            }, 0)
                            .from(els.piggy, {
                                duration: .5,
                                fill: '#000000',
                                scale: .9,
                                transformOrigin: 'center bottom'
                            }, 0)

                        return tl;
                    },
                    function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
                    textPaths: svg.querySelectorAll('#text path'),
                    bills: svg.querySelectorAll('#money > g'),
                    fromBlack: svg.querySelectorAll('#to-black > *'),
                    fromWhite: svg.querySelectorAll('#euro, #stripe-1'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.fromBlack, {duration: .25, fill: '#000000'}, 0)
                    .from(els.fromWhite, {duration: .25, fill: '#f5f1e5'}, 0)
                    .to(els.bills, {
                        duration: .5,
                        rotation: function (index) {
                            return index * -3;
                        },
                        x: function (index) {
                            return index * 5;
                        },
                        y: function (index) {
                            return index * -5;
                        },
                        transformOrigin: '0 -20'
                    }, 0)

                return tl;
            },
                ],
            }

            return timelines[step][(choice - 1)](svg);
        }
    }
}