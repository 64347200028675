<template>
    <section>
        <h1>Welche Antriebsart bevorzugst du?</h1>
        <div class="choices">
            <inline-svg @click.native="clickHandler(1, $event, timeline1)" ref="choice1" @loaded="setupTimeline($event, initTimeline1($event))" class="icon" :src="gfx.svgKonventionell" alt="Konventionell" />
            <inline-svg @click.native="clickHandler(2, $event, timeline2)" ref="choice2" @loaded="setupTimeline($event, initTimeline2($event))" class="icon" :src="gfx.svgHybrid" alt="Hybrid" />
            <inline-svg @click.native="clickHandler(3, $event, timeline3)" ref="choice2" @loaded="setupTimeline($event, initTimeline3($event))" class="icon" :src="gfx.svgElektrisch" alt="Elektrisch" />
        </div>
        <DefaultButton @click.native="clickHandler(0, $event, null)">Keine Angabe</DefaultButton>
    </section>
</template>

<script>
    import gsap from 'gsap'
    import InlineSvg from 'vue-inline-svg';
    import DefaultButton from "../components/DefaultButton";
    import stepMixin from "../mixins/stepMixin";

    let gfx = {
        svgKonventionell: require('../assets/gfx/anim_konventionell.svg'),
        svgHybrid: require('../assets/gfx/anim_hybrid.svg'),
        svgElektrisch: require('../assets/gfx/anim_elektrisch.svg'),
    }

    export default {
        name: "step1",
        mixins: [stepMixin],
        components: {
            DefaultButton,
            InlineSvg
        },
        data: function() {
            return {
                gfx,
                timeline1: null,
                timeline2: null,
                timeline3: null,
            }
        },
        mounted() {
          if(typeof window.gtag === 'function') {
            window.gtag('event', 'page_view')
          }

        },
        methods: {
            initTimeline1: function (svg) {
                gsap.defaults({ease: "power2.inOut"});
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),

                    drops: svg.querySelectorAll('#drop-1, #drop-2'),
                    nozzle: svg.querySelector('#nozzle'),
                    nozzleParts: svg.querySelectorAll('#nozzle > *'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 100%'}, 0)
                    .from(els.nozzle, {duration: .25, rotation: 30, transformOrigin: 'center center'}, 0)
                    .from(els.nozzleParts, {duration: .5, fill: '#000000'}, 0)
                    .from(els.drops, {duration: .24, scale: 0, transformOrigin: 'center top', stagger: -.1}, .25)

                return this.timeline1 = tl;
            },
            initTimeline2: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    icons: svg.querySelector('#icons'),
                    dropIcon: svg.querySelector('#drop-icon'),
                    flashIcon: svg.querySelector('#flash-icon'),
                    battery: svg.querySelector('#battery'),
                    nozzle: svg.querySelectorAll('#nozzle, #drop'),
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 100%'}, 0)
                    .to(els.dropIcon, {duration: .5, x: '+=50', y: '-=30'}, 0)
                    .to(els.flashIcon, {duration: .5, x: '-=50', y: '+=30'}, 0)
                    .to(els.icons, {duration: .5, opacity: 0}, 0)
                    .from(els.battery, {duration: .5, opacity: 0, x: '+=50'}, 0)
                    .from(els.nozzle, {duration: .5, opacity: 0, x: '-=50'}, 0)

                return this.timeline2 = tl;
            },
            initTimeline3: function (svg) {
                let tl = gsap.timeline({paused: true});

                let els = {
                    text: svg.querySelector('#text'),
textPaths: svg.querySelectorAll('#text path'),
                    flash: svg.querySelector('#flash'),
                    poles: svg.querySelectorAll('#minus, #plus'),
                    case: svg.querySelector('#case'),
                    caseParts: svg.querySelectorAll('#case > *'),
                    battery: svg.querySelector('#battery')
                };

                tl
                    .from(els.textPaths, {duration: .5, fill: '#000000'}, 0)
                    .from(els.text, {duration: .5, scale: .8, transformOrigin: 'center 200%'}, 0)
                    .from(els.battery, {duration: .5, scale: .8, transformOrigin: 'center bottom'}, 0)
                    .from(els.caseParts, {duration: .5, fill: '#000000'}, 0)
                    .from(els.flash, {duration: .5, fill: '#f5f1e5', scale: .5, transformOrigin: 'center center'}, 0)
                    .from(els.poles, {duration: .5, fill: '#f5f1e5'}, 0)

                return this.timeline3 = tl;
            }
        }
    }
</script>

<style scoped>

</style>