<template>
    <div v-bind:class="{disabled: atTheBottom}">

    </div>
</template>

<script>
    export default {
        name: "ScrollIndicator",
        props: ['visible'],
        data: function() {
            return {
                atTheBottom: false,
            }
        },
        created() {
            window.addEventListener('scroll', this.scrollHandler);
        },
        mounted() {
          window.setTimeout(this.scrollHandler, 1000);
        },
        methods: {
            scrollHandler: function () {
                let d = document.documentElement;
                let offset = d.scrollTop + window.innerHeight;
                let height = d.offsetHeight;

                this.atTheBottom = (offset >= height);
            },
        }
    }
</script>

<style scoped lang="scss">
    @keyframes scrollIndicator {
        from {
            border-color: rgba(0, 0, 0, 0.5);
            transform: translateY(-2rem) rotate(-45deg) scale(.8);
        }
        to{
            border-color: rgba(0, 0, 0, 1);
            transform: translateY(0) rotate(-45deg) scale(1);
        }
    }

    @include mobile {
        div {
            content: '';
            position: fixed;
            bottom: 1rem;
            left: .6rem;
            width: .8rem;
            height: .8rem;
            border: 2px solid black;
            border-top: none;
            border-right: none;
            transition: opacity .5s;
            transform: rotate(-45deg);
            animation-name: scrollIndicator;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-duration: 1s;
            animation-timing-function: linear;

            &.disabled {
                opacity: 0 !important;
            }
        }
    }

</style>